<template>
  <div v-if="!this.$can('Read', 'Dashboard')" style="align-items: center;padding: 16px; text-align: center;">
      <!-- <p class="">Oops! 😖 You cannot access the requested URL.</p> -->
      <b-img
        style="scale: 0.8;"
          fluid
          :src="notAuthorized"
          alt="Error page"
        />
        <h1 class="mt-2">{{ $t('You dont have permission view this page') }}</h1>
  </div>
  <div v-else class="dashboard-container">
    <div>
      <div class="tw-text-2xl tw-font-bold text-body">{{ $t(title) }}</div>
      <div>
        {{ $t(description) }}
      </div>
    </div>
    <div>
      <div class="d-flex" style="justify-content: flex-end; margin-right: 12px;">
        <b-dropdown
          size="md"
          variant="outline-primary"
          style="width: 240px; height: 32px; margin-right:6px;"
        >
          <template #button-content>
            <span class="text-nowrap">{{
              periodType == 'custom' ?
                "Custom Date"
                : yearFilter != null ? yearFilter : $t('Period')
            }}
            </span>
          </template>
          <b-dropdown-text style="width: 200px">{{ $t('Select Year or Date Range') }}</b-dropdown-text>
          <b-dropdown-item :key="custom" @click="setCustomDateFilter();">
            {{ $t('Custom Date') }}
          </b-dropdown-item>
          <b-dropdown-item
            v-for="year in years"
            :key="year.name"
            @click="setCustomYearFilter(year.name);"
          >
            {{ year.name }}
          </b-dropdown-item>
        </b-dropdown>
        <div v-if="isCustomDateFilter()" style="height: 32px;">
          <date-range-filter
            ref="dateRangeFilter"
            style="width: 200px; margin: 0px; margin-top: -10px;"
            @applyDateFilter="applyDateFilter"
          />
        </div>
      </div>
    </div>
    <div
      v-if="loading"
      class="row-flex-justify-center"
    >
      <b-spinner label="Spinning" />
    </div>
    <div
      v-else
      class="__graph-container"
    >
      <div
        v-for="(row, index) in widgets"
        :key="index"
        class="bg-card tw-text-body tw-mt-5 widget-creation"
        :class="{ 'widget-creation-wrap': row.length >= 3 }"
        :style="setRowHeight(row)"
      >
        <div
          v-for="(widget, widgetIndex) in row"
          :key="widgetIndex"
          class="widget-creation__form"
        >
          <dashboard-chart-header
            v-if="typeof widget === 'object'"
            :data="widget"
            :indexes="{ parentIndex: index, childIndex: widgetIndex }"
          />
          <component
            :is="findChartComponent(widget.type)"
            class="widget-component"
            :type="widget.type"
            :result="widget.result"
            :chart-data="widget"
            :dashboard-id="widget.uuidKey"
            :for-preview="widget.type === 'TABLE'"
            :date-range="dateRange"
            :dashboard-filter-values="widget.type === 'TABLE' && widget.filters"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dataTable, graphMixin } from '@/mixins/dashboard'
import { dashboardService } from '@/services/dashboard'
import KpiChart from '@/components/shared/KpiChart.vue'
import WidgetDataSourceTable from '@/components/Widgets/WidgetDataSourceTable.vue'
import ProzessChart from '@/components/chart/ProzessChart.vue'
import DashboardChartHeader from '@/components/Dashboard/DashboardChartHeader.vue'
import RootEvents from '@/constants/rootEvents'
import DateRangeFilter from '@/components/Lists/DateRangeFilter.vue'

export default {
  components: {
    KpiChart,
    WidgetDataSourceTable,
    ProzessChart,
    DashboardChartHeader,
    DateRangeFilter,
  },

  mixins: [dataTable, graphMixin],
  data() {
    return {
      title: '',
      description: '',
      widgets: [],
      loading: false,
      yearFilter: null,
      periodType: null,
      years: Array.from({ length: new Date().getFullYear() - 2022 }, (_, i) => new Date().getFullYear() - i).map(year => ({
        name: year,
      })),
      dateRange: [],
      notAuthorized: require('@/assets/images/pages/not-authorized.svg'),
    }
  },

  watch: {
    '$route.params.id': {
      handler(newVal) {
        this.fetchData()
      },
    },
  },
  mounted() {
   this.setCustomYearFilter(new Date().getFullYear())

    this.registerBusEvent(RootEvents.DASHBOARD_FILTER_APPLY, async payload => {
      const parentIndex = payload.indexes.parentIndex
      const childIndex = payload.indexes.childIndex
      const response = await this.$async(
        dashboardService('widget').getDashboardWidgetData(this.$route.params.id, payload.uuidKey, {
          page: 0,
          userFilterValues: payload.filter,
          startAt: this.dateRange.length > 0 ? this.dateRange[0] : null,
          endAt: this.dateRange.length > 1 ? this.dateRange[1] : null,
        }),
      )

      this.widgets[parentIndex][childIndex] = response.response.data
      this.$forceUpdate()
    })
  },

  methods: {
    async applyDateFilter(val) {
      this.dateRange = val
      await this.fetchData()
    },
    isCustomDateFilter() {
      return this.periodType === 'custom'
    },
    async setCustomYearFilter(year) {
        this.yearFilter = year
        this.periodType = 'year'
        this.dateRange = [`01-01-${year}`, `12-31-${year}`]
        await this.fetchData()
    },
    setCustomDateFilter() {
      if (this.periodType !== 'custom') {
        this.$router.push({ query: { page: 1 } })
        this.periodType = 'custom'
        this.yearFilter = null
      }
    },
    setRowHeight(row) {
      const style = {
        height: 'auto',
      }

      const hasTable = row.some(item => item.type === 'TABLE')
      const hasChart = row.some(item => item.type === 'LINE' || item.type === 'MULTILINE' || item.type === 'BAR' || item.type === 'GROUPED_BAR')
      if (hasTable) style.height = '650px'
      else if (hasChart) style.height = '600px'
      return style
    },
    findChartComponent(data) {
      const type = data.toLowerCase()
      if (type === 'kpi') return 'kpi-chart'
      if (type === 'line' || type === 'bar' || type === 'pie' || type === 'grouped_bar' || type === 'multiline' || type === 'tree') return 'prozess-chart'
      return 'widget-data-source-table'
    },
    async fetchData() {
      const startAt = this.dateRange.length > 0 ? this.dateRange[0] : null
      const endAt = this.dateRange.length > 1 ? this.dateRange[1] : null

      this.loading = true
      this.widgets = []
      const { response } = await this.$async(dashboardService('dashboard').dashboarView(this.$route.params.id))
      const { data } = response
      this.title = data.name
      this.description = data.description

      this.widgets = data.widgetIds
      data.widgetIds.forEach(async (_widgets, index) => {
        const promise = _widgets.map(async (widget, childIndex) => {
          const widgetDataResponse = await this.$async(
            dashboardService('widget').getDashboardWidgetData(this.$route.params.id, widget.uuidKey, {
              page: 0,
              startAt,
              endAt,
            }),
          )
          this.widgets[index][childIndex] = widgetDataResponse.response.data
        })

        await Promise.all(promise)

        this.$forceUpdate()

        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/master-variables.scss';

$mobile-breakpoint: 958px;
$tablet-breakpoint: 1200px;
.dashboard-container {
  .widget-creation {
    position: relative;
    display: flex;
    &__form {
      background: var(--colour--card-bg);
      position: relative;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-left: 0;
      border-radius: 15px;
      height: 100%;
      min-height: 200px;
      height: 100%;
      padding-bottom: 20px;
      overflow: hidden;
      .widget-component {
        overflow-x: auto;
        // overflow-y: hidden;
      }
      .kpi-widget-container {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 250px;
      }
    }
  }

  .result-table-container {
    padding-left: 25px;
    padding-right: 25px;
  }
  .widget-creation__form {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  @media (max-width: $tablet-breakpoint) {
    .widget-creation {
      flex-wrap: wrap;
      flex-direction: row;
      height: 100% !important;
      justify-content: center;
      align-items: stretch;
      .widget-creation__form {
        width: 100% !important;
        margin-bottom: 12px !important;
      }
    }
  }

  @media (max-width: $mobile-breakpoint) {
    .widget-creation,
    .widget-creation-wrap {
      height: auto !important;
      display: flex;
      flex-direction: column;
      &__form {
        width: 100% !important;
      }
    }
  }
}
</style>
